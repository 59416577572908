<template>
  <div>
    <SettingModal ref="SettingModal" @update="onSettingData" />

    <StatFilter ref="StatFilter" :PERIOD="cycle" @query="onQuery">
      <CCardHeader slot="header">
        <CRow class="justify-content-between px-2">
          <span>
            <CButton color="light" @click="onShowFilter"><CIcon name="expand" /></CButton>
            <CButton class="ml-2" color="light" @click="onResetFilter"><CIcon name="refresh" />{{$t('cloud.title.filter_reset')}}</CButton>
          </span>
          <CDropdown color="light">
            <span slot="toggler-content"><CIcon class="mr-1" name="cil-settings" size="sm" />{{$t('cloud.title.setting')}}</span>
            <CDropdownItem @click="onSettingOpen"><CIcon class="mr-2" name="cil-bar-chart" />{{$t('cloud.title.view_setting')}}</CDropdownItem>
            <CDropdownItem @click="onExportOpen"><CIcon class="mr-2" name="file_download" />{{$t('button.export')}}</CDropdownItem>
          </CDropdown>
          <!-- <CButton color="light" @click="onExportOpen"><CIcon class="mr-2" name="file_download" />{{$t('button.export')}}</CButton> -->
        </CRow>
      </CCardHeader>
    </StatFilter>

    <CRow v-if="load_done">
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t('cloud.title.occupancy_visitor_count')" :text="`${stats.today_total}`">
          <h5 :class="{'text-success':todayTotalIncrease >= 0,'text-danger':todayTotalIncrease<0}">
            <svgicon :name="todayTotalIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(todayTotalIncrease*todayTotalIncrease)}}</span>
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header-size="4" :header="$t('cloud.title.gender_domin_title')" :text="genderDist">
          <h5 class="text-dark">
            <CIcon name="male" size="xl" class="text-info" />
            <span>{{malePortion?`${malePortion}%`:'-'}}</span>
            <CIcon name="female" size="xl" class="text-danger" />
            <span>{{malePortion?`${100-malePortion}%`:'-'}}</span>
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t('cloud.title.gender_male')" :text="`${stats.today_male}`">
          <h5 :class="{'text-success':todayMaleIncrease >= 0,'text-danger':todayMaleIncrease<0}">
            <svgicon :name="todayMaleIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(todayMaleIncrease*todayMaleIncrease)}}</span>
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t('cloud.title.gender_female')" :text="`${stats.today_female}`">
          <h5 :class="{'text-success':todayFemaleIncrease >= 0,'text-danger':todayFemaleIncrease<0}">
            <svgicon :name="todayFemaleIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(todayFemaleIncrease*todayFemaleIncrease)}}</span>
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol xl="12" xxl="6">
        <CardBarCharts
          :title="$t('cloud.title.people_count')"
          :stats="[stats.total, stats.total_yesterday]"
          :labels="[$t($resource.TARGET_STR_KEY[cycle]), $t($resource.COMPARE_STR_KEY[cycle])]"
          :colors="['#f6960b', '#fcd88a']"
        />
      </CCol>
      <CCol xl="12" xxl="6">
        <CardPieCharts
          :title="$t('cloud.title.gender_distribution')"
          :stats="[stats.today_male, stats.today_female]"
          :labels="[$t('cloud.title.gender_male'), $t('cloud.title.gender_female')]"
          :colors="['#358ec4', '#e65054']"
        />
      </CCol>
      <CCol xl="12" xxl="6">
        <CardBarCharts
          :title="$t('cloud.title.card_gender_distribution')"
          :stats="[stats.male, stats.female]"
          :labels="[$t('cloud.title.gender_male'), $t('cloud.title.gender_female')]"
          :colors="['#2982cc', '#e55353']"
          :stack-names="['today', 'today']"
          stack
          y_stack
        />
      </CCol>
      <CCol xl="12" xxl="6">
        <CardBarCharts
          :title="$t('cloud.title.card_gender_distribution')"
          :stats="[stats.male, stats.female, stats.male_yesterday, stats.female_yesterday]"
          :labels="[
            $t('cloud.title.gender_male'),
            $t('cloud.title.gender_female'),
            `${$t('cloud.title.gender_male')}(${$t($resource.COMPARE_STR_KEY[cycle])})`,
            `${$t('cloud.title.gender_female')}(${$t($resource.COMPARE_STR_KEY[cycle])})`
          ]"
          :colors="['#2982cc', '#e55353', '#80c6ff', '#f2a9a9']"
          :stack-names="['today', 'today', 'yesterday', 'yesterday']"
          stack
          y_stack
        />
      </CCol>
    </CRow>
    <div v-loading="!load_done" v-if="!load_done" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import axios from 'axios'
import qs from 'qs'
import StatFilter from './StatFilter.vue'
import CardSimpleNumber from '@/components/cards/SimpleNumber.vue'
import CardPieCharts from '@/components/cards/PieCharts.vue'
import CardBarCharts from '@/components/cards/BarCharts.vue'
import SettingModal from '@/components/modals/SettingModal.vue'

export default {
  name: 'RetailVisitAdvancedAnalytics',

  components: {
    StatFilter,
    CardSimpleNumber,
    CardPieCharts,
    CardBarCharts,
    SettingModal
  },

  computed: {
    ...mapState([
      'capability'
    ]),
    todayTotalIncrease() {
      return (this.stats.today_total - this.stats.today_total_yesterday);
    },
    todayMaleIncrease() {
      return (this.stats.today_male - this.stats.today_male_yesterday);
    },
    todayFemaleIncrease() {
      return (this.stats.today_female - this.stats.today_female_yesterday);
    },
    genderDist() {
      let dist_str = null;
      let total = this.stats.today_male + this.stats.today_female;
      if (total <= 0) {
        return "-";
      }
      let male_ratio = parseInt(this.stats.today_male / total * 100);
      if (male_ratio < 40) {
        dist_str = `${this.$t('cloud.title.gender_domin_female')}`;
      } else if (male_ratio < 60) {
        dist_str = this.$t('cloud.title.gender_domin_even');
      } else {
        dist_str = `${this.$t('cloud.title.gender_domin_male')}`;
      }
      return dist_str;
    },
    malePortion() {
      let total = this.stats.today_male + this.stats.today_female;
      if (total <= 0) return null;
      return parseInt(this.stats.today_male / total * 100);
    }
  },
  data() {
    return {
      load_done: false,
      loading: {
        gender: true
      },
      cycle: 'daily',
      filters: null,
      stats: {
        today_total: 0,
        today_total_yesterday: 0,
        today_male: 0,
        today_male_yesterday: 0,
        today_female: 0,
        today_female_yesterday: 0,
        total: null,
        male: null,
        female: null,
        total_yesterday: null,
        male_yesterday: null,
        female_yesterday: null,
      }
    }
  },
  methods: {
    onQuery(stat_filter) {
      this.load_done = false;
      this.filters = stat_filter;
      if (this.filters && this.filters.date) {
        this.statGender();
      }
    },
    buildQs(date_before) {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        params.device = this.filters.device;
        params.counter = this.filters.counter;
        params.t_from = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", this.filters.dates[0]);
        params.t_to = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", this.filters.dates[1]);
        params.interval = this.filters.unit;
      }
      let from_ts = this.filters.dates[0].getTime();
      let to_ts = this.filters.dates[1].getTime();
      for (let i=0; i<date_before; i++) {
        let temp_t_to = from_ts;
        let temp_t_from = temp_t_to - (to_ts-from_ts);
        from_ts = temp_t_from;
        to_ts = temp_t_to;
      }
      if (date_before) {
        params.t_from = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", new Date(from_ts));
        params.t_to = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", new Date(to_ts));
      }
      if (params) {
        query_string = '?' + qs.stringify(params);
      }
      return query_string;
    },
    statGender() {
      const query_string = this.buildQs();
      const query_string_yesterday = this.buildQs(1);
      let loading_today = true;
      let loading_yesterday = true;

      this.loading.gender = true;

      axios.get(`/api/counter/gender/stat/class/${query_string}`)
        .then(result => {
          this.stats.total = [];
          this.stats.male = [];
          this.stats.female = [];
          result.data.map(el => {
            const total_val = el.count_t;
            const male_val = el.details.man.count_t;
            const female_val = el.details.woman.count_t;
            const created_at = el.created_at;
            this.stats.total.push({value: total_val, x: created_at});
            this.stats.male.push({value:male_val, x: created_at});
            this.stats.female.push({value:female_val, x: created_at});
          });
          this.stats.today_total = 0;
          for (const item of this.stats.total) {
            this.stats.today_total += item.value;
          }
          this.stats.today_male = 0;
          for (const item of this.stats.male) {
            this.stats.today_male += item.value;
          }
          this.stats.today_female = 0;
          for (const item of this.stats.female) {
            this.stats.today_female += item.value;
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          loading_today = false;
          if (!loading_yesterday) {
            this.loading.gender = false;
            this.checkLoading();
          }
        })

      axios.get(`/api/counter/gender/stat/class/${query_string_yesterday}`)
        .then(result => {
          this.stats.total_yesterday = [];
          this.stats.male_yesterday = [];
          this.stats.female_yesterday = [];
          result.data.map(el => {
            const total_val = el.count_t;
            const male_val = el.details.man.count_t;
            const female_val = el.details.woman.count_t;
            const created_at = el.created_at;
            this.stats.total_yesterday.push({value: total_val, x: created_at});
            this.stats.male_yesterday.push({value:male_val, x: created_at});
            this.stats.female_yesterday.push({value:female_val, x: created_at});
          });

          this.stats.today_total_yesterday = 0;
          for (const item of this.stats.total_yesterday) {
            this.stats.today_total_yesterday += item.value;
          }
          this.stats.today_male_yesterday = 0;
          for (const item of this.stats.male_yesterday) {
            this.stats.today_male_yesterday += item.value;
          }
          this.stats.today_female_yesterday = 0;
          for (const item of this.stats.female_yesterday) {
            this.stats.today_female_yesterday += item.value;
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          loading_yesterday = false;
          if (!loading_today) {
            this.loading.gender = false;
            this.checkLoading();
          }
        })
    },
    checkLoading() {
      if (!this.loading.gender) {
        setTimeout(() => {
          this.load_done = true;
        }, 1500);
      }
    },


    // 내보내기
    onExportOpen() {
      // this.$refs.ExportModal.open();
      let param = {
        stats: this.stats,
        cycle: this.cycle
      };
      let param_str = JSON.stringify(param);
      let param_encoded = window.btoa(unescape(encodeURIComponent(param_str)));
      this.$router.push(`/report/apps/visit_advanced?p=${param_encoded}`);
    },
    onSettingOpen() {
      this.$refs.SettingModal.open();
    },
    onSettingData() {
      const record = this.$refs.SettingModal.getData();
      this.cycle = record.cycle;
      setTimeout(() => {
        this.$refs.StatFilter.onQuery();
      }, 1);
    },
    onResetFilter() {
      this.$refs.StatFilter.onReset();
    },
    onShowFilter() {
      this.$refs.StatFilter.showFilter();
    }
  }
}
</script>